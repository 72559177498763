@import "../style/var.scss";

$toggle-width: 52px;
$toggle-height: 24px;

.Toggle {
  margin-top: 55px;

  .toggleLabel {
    display: block;
    text-align: center;
    font-weight: $bold;
    color: $main-color;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .toggleGroup {
    display: flex;
    justify-content: center;

    .value {
      flex-grow: 1;
      width: calc(50% - #{$toggle-width / 2});
      display: flex;
      align-items: center;

      &.falseValue {
        justify-content: flex-end;
      }

      &.trueValue {
        justify-content: flex-start;
      }
    }

    .toggleControl {
      width: $toggle-width;
      height: $toggle-height;
      border-radius: 12px;
      margin-left: 10px;
      margin-right: 10px;
      background-color: $bg-color;
      position: relative;
      transition: filter 0.2s;

      &::after {
        content: "";
        display: inline-block;
        height: 18px;
        width: 18px;
        border-radius: 9px;
        top: 3px;
        right: 3px;
        position: absolute;
        background-color: $main-color;
        transition: right 0.2s;
      }

      &[data-value="false"] {
        filter: grayscale(1);

        &::after {
          right: 27px;
        }
      }
    }
  }
}
